@import url('https://fonts.googleapis.com/css?family=Exo+2:regular,bold,italic&display=swap');

body {
  font-family: "Exo 2";
  background-color: #0B2D5A;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}
